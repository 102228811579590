import { format } from 'date-fns';

function stripTimezoneFromDateString(dateString) {
	const plusPos = dateString.indexOf('+');
	return plusPos === -1 ? dateString : dateString.substr(0, plusPos);
}

export function parseDate(dateString) {
	if (dateString instanceof Date) {
		return dateString;
	}

	return dateString != null ? new Date(stripTimezoneFromDateString(dateString)) : null;
}

export function formatDateRange(startDate, endDate) {
	const start = parseDate(startDate);
	const end = parseDate(endDate);

	if (!endDate) {
		return format(start, 'do MMMM');
	}

	if (
		start.getFullYear() === end.getFullYear() &&
		start.getMonth() === end.getMonth()
	) {
		if (start.getDate() === end.getDate()) {
			return format(end, 'do MMMM yyyy');
		}

		return `${format(start, 'do')} - ${format(end, 'do MMMM yyyy')}`;
	}

	return `${format(start, 'do MMMM')} - ${format(end, 'do MMMM yyyy')}`;
}

export function formatFullDate(date) {
	return format(parseDate(date), 'do MMMM yyyy');
}

export function formatMonthAndYear(date) {
	return format(parseDate(date), 'MMMM yyyy');
}

export function getNextMonthsFromDate(
	date = new Date(),
	numberOfNextMonths = 12
) {
	const selectMonthsOptions = [];

	for (let month = 1; month <= numberOfNextMonths; month += 1) {
		if (month !== 1) {
			date.setMonth(date.getMonth() + 1);
		}

		selectMonthsOptions.push({
			displayText: formatMonthAndYear(date),
			filter: new Date(date.getFullYear(), date.getMonth()).toISOString(),
		});
	}

	return selectMonthsOptions;
}

