import { getHours, getMinutes } from 'date-fns';
import { formatDateRange, formatFullDate, parseDate } from './dates';

function convertToTwelveHour(hour) {
	if (hour === 0) {
		return 12;
	}

	if (hour > 12) {
		return hour - 12;
	}

	return hour;
}

function getMeridian(hour) {
	if (hour < 12) {
		return 'am';
	}

	return 'pm';
}

export function getEventTime(eventStartDate, eventEndDate) {
	const times = [eventStartDate, eventEndDate];
	const timeParts = [];

	times.forEach(time => {
		const dateParts = [];
		const date = parseDate(time);

		const hour = getHours(date, 'H');
		dateParts.push(convertToTwelveHour(hour));

		const minutes = getMinutes(date, 'mm');
		// eslint-disable-next-line no-unused-expressions
		minutes !== 0 && dateParts.push(minutes);

		timeParts.push(dateParts.join('.') + getMeridian(hour));
	});
	return timeParts.join(' to ');
}

export function buildArticleDescription(article) {
	const descriptionParts = [];

	if (article.type === 'Events') {
		if (article.eventStartDate && article.eventEndDate) {
			let dateRange = formatDateRange(article.eventStartDate, article.eventEndDate);

			const start = parseDate(article.eventStartDate);
			const end = parseDate(article.eventEndDate);
			if (
				start.getFullYear() === end.getFullYear() &&
				start.getMonth() === end.getMonth() &&
				start.getDate() === end.getDate()
			) {
				dateRange = `${dateRange}, ${getEventTime(
					article.eventStartDate,
					article.eventEndDate
				)}`;
			}

			descriptionParts.push(dateRange);
		}
	}

	if (article.type !== 'Events') {
		const date = article.publishDate && formatFullDate(article.publishDate);
		// eslint-disable-next-line no-unused-expressions
		date && descriptionParts.push(date);
	}

	if ((article.location || '').trim() !== '') {
		descriptionParts.push(article.location);
	}

	return descriptionParts.join(' - ');
}

export function buildArticleSubTitle(article) {
	let subTitle = '';
	if ((article.authorName || article.author || '').trim() !== '') {
		subTitle = `By ${article.authorName ? article.authorName : article.author}`;
	}
	return subTitle;
}

export function buildArticleLink(article) {
	const isEmpty = word => (word || '').trim() !== '';

	if (article.type === 'Events' && isEmpty(article.externalLinkUrl)) {
		return article.externalLinkUrl;
	}

	if (article.type === 'Events' && isEmpty(article.eventLink)) {
		return `/${article.eventLink}`;
	}

	if (article.type === 'Events' && isEmpty(article.articleId)) {
		return `/${article.articleId}`;
	}

	return `/${article.articleId}`;
}
