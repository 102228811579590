import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import ArticleCard from '../../molecules/ArticleCard';
import Button from '../../atoms/Button';
import Styled from './index.styled';
import {
	buildArticleSubTitle,
	buildArticleDescription,
	buildArticleLink,
} from '../../../lib/buildArticleContent';

type ArticleListProps = {
	title: string
	subText: string
	articles: any[]
	numberOfArticlesPerGroup: number
	showMoreButtonText: string
	customCtaLink: any
	articleStyling?: string
	subSite: string
	anchorId?: string
};

const DynamicArticleList = (props: ArticleListProps) => {
	const {
		title,
		subText,
		articles,
		numberOfArticlesPerGroup,
		showMoreButtonText,
		customCtaLink,
		subSite,
		articleStyling,
		anchorId,
	} = props;
	const theme = useContext(ThemeContext);
	const [articleLimit, setArticleLimit] = useState(numberOfArticlesPerGroup);

	return (
		<Styled.Content__Articles as="section" id={anchorId || ''}>
			<Styled.Content__Title as="h2">{title}</Styled.Content__Title>
			<Styled.Content__SubText>{subText}</Styled.Content__SubText>
			<Styled.Content__Articles_Grid as="section">
				{articles.slice(0, articleLimit).map(article => (
					<ArticleCard
						key={article.id}
						title={article.title}
						subtitle={buildArticleSubTitle(article)}
						description={buildArticleDescription(article)}
						link={buildArticleLink(article)}
						isExternalLink={
							article.type === 'Events' && !!article.externalLinkUrl
						}
						image={article.image}
						theme={theme}
						styling={articleStyling}
						subSite={subSite}
					/>
				))}
			</Styled.Content__Articles_Grid>
			{articleLimit < articles.length && (
				<Styled.Content__LoadMore as="section">
					{articleLimit && !customCtaLink && (
						<Button
							buttonText={showMoreButtonText}
							buttonType="tertiary"
							callback={() =>
								setArticleLimit(articleLimit + numberOfArticlesPerGroup)
							}
						/>
					)}
					{customCtaLink && (
						<Button
							buttonText={customCtaLink.title}
							buttonLink={customCtaLink.url}
							buttonCategory={customCtaLink.dataGACategory}
							buttonAction={customCtaLink.dataGAAction}
							buttonLabel={customCtaLink.dataGALabel}
							buttonType="tertiary"
						/>
					)}
				</Styled.Content__LoadMore>
			)}
		</Styled.Content__Articles>
	);
};

export default DynamicArticleList;
