import styled, { css } from 'styled-components';
import { Container } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import {
	lgTitleRegular,
	smBodyLight,
} from '../../atoms/Typography/index.styled';

const Content__Title = styled(lgTitleRegular)`
	margin-bottom: ${gap[40]};
	color: var(--primaryForeground);
`;

const Content__SubText = styled(smBodyLight)`
	margin-bottom: ${gap[40]};
	color: var(--primaryForeground);

	${above(
		breakpoints[960],
		() => css`
			max-width: 50%;
		`
	)}
`;

const Content__Articles = styled(Container)`
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};
	background-color: var(--primaryBackground);

	${above(
		breakpoints[1440],
		() => css`
			padding-top: ${spacing[80]};
			padding-bottom: ${spacing[80]};
		`
	)}
`;

const Content__Articles_Grid = styled.div`
	display: grid;
	grid-gap: ${gap[32]};
	grid-template-columns: 1fr;

	${above(
		breakpoints[768],
		() => css`
			grid-gap: ${gap[24]};
			grid-template-columns: 1fr 1fr 1fr;
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			grid-gap: ${gap[32]};
			grid-template-columns: 1fr 1fr 1fr;
		`
	)}
`;

const Content__LoadMore = styled.div`
	margin-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};

	${above(
		breakpoints[1440],
		() => css`
			padding-bottom: ${spacing[80]};
		`
	)}
`;

export default {
	Content__Title,
	Content__SubText,
	Content__Articles,
	Content__Articles_Grid,
	Content__LoadMore,
};
